// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"src/modules/cart/fetchers/continue-shopping-fetcher.ts\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "src/modules/cart/fetchers/continue-shopping-fetcher.ts");
  import.meta.hot.lastModified = "1740495432000";
}
// REMIX HMR END

import React from 'react';
import { useFetcher, useNavigate } from '@remix-run/react';
import { getAlertServer } from '~common/components/alert/server/index.server';
import { config } from '~storefront/modules/cart/config';
import { OrderState } from '~common/utils/orderUtils';
import { getActiveOrder, transitionOrderToState } from '~storefront/providers/order/provider';
export async function action({
  request
}) {
  const alertServer = getAlertServer();
  const formData = await request.formData();
  let error;
  let data;
  let headers;
  let order;
  const state = OrderState.ADDING_ITEMS;
  try {
    const currentOrder = await getActiveOrder({
      request
    });
    if (!currentOrder) {
      return alertServer.redirectWithError('/', {
        title: 'Missing order',
        message: ''
      }, {
        headers
      });
    }
    if (currentOrder.state === OrderState.ADDING_ITEMS) {
      return alertServer.redirectWithSuccess('/', {
        title: 'Success',
        message: 'Please continue browsing'
      }, {
        headers
      });
    }
    const result = await transitionOrderToState(state, {
      request
    });
    if (!result || 'errorCode' in result) {
      throw new Error(result?.message ?? 'Order not transitioned');
    }
  } catch (err) {
    console.log('err1', err, err.message);
    error = err.message;
  }
  if (error) {
    return alertServer.redirectWithError(config.path, {
      title: 'Something went wrong',
      message: error
    }, {
      headers
    });
  }
  return alertServer.redirectWithSuccess('/', {
    title: 'Success',
    message: 'Please continue browsing'
  }, {
    headers
  });
}
export const ContinueShoppingFetcher = ({
  order,
  transitionToState = 'AddingItems',
  children
}) => {
  _s();
  const fetcher = useFetcher({
    key: 'continue-shopping'
  });
  const navigate = useNavigate();
  const {
    state
  } = fetcher;
  const handleSubmit = React.useCallback(() => {
    if (order?.state === 'AddingItems') {
      navigate('/');
      return;
    }
    fetcher.submit({
      state: transitionToState
    }, {
      action: `${config.apiPath}/continue-shopping`,
      method: 'POST'
    });
  }, [transitionToState, navigate, order]);
  return children({
    onChangeOrderState: handleSubmit,
    loading: state !== 'idle'
  });
};
_s(ContinueShoppingFetcher, "bZEHamIsW01nWbFnjDoNz9AQ25c=", false, function () {
  return [useFetcher, useNavigate];
});
_c = ContinueShoppingFetcher;
var _c;
$RefreshReg$(_c, "ContinueShoppingFetcher");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;